// See https://github.com/mui-org/material-ui/tree/master/examples/gatsby
import {SheetsRegistry} from "jss";
import {createMuiTheme} from "@material-ui/core/styles";
import {createGenerateClassName} from '@material-ui/styles';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#44acc9',
      contrastText: 'white',
    },
    secondary: {
      main: '#44c9a3',
    },
    text: {
      primary: "rgba(18, 18, 18, 0.87)"
    }
  },
  typography: {
    useNextVariants: true,
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h1: {
      fontSize: '2.2rem',
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
  }
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
