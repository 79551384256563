import React from "react";
import {graphql, Link, StaticQuery} from "gatsby";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/styles/withStyles";

const styles = theme => ({
  menuButton: {
    color: theme.palette.primary.contrastText
  }
});

const Menu = withStyles(styles)(props => {
  const {
    classes,
    data: {
      site: {
        siteMetadata: { menuLinks }
      }
    }
  } = props;
  return (
    <>
      {menuLinks.map(link => (
        <Link key={link.name} to={link.link} style={{marginLeft: 30}}>
          <Button className={classes.menuButton}>{link.name}</Button>
        </Link>
      ))}
    </>
  );
});

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} />}
  />
);
