import React from "react";
import CookieConsent, {Cookies } from "react-cookie-consent"
import Header from "./Header";
import Footer from "./Footer";
import { Grid, Typography } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import "../css/style.styl";
import {Link} from 'gatsby'

const styles = {
  container: {
    marginTop: 94,
  },
  contentBox: {
    maxWidth: "calc(1136px - 60px)",
    width: "calc(100% - 60px)",
  },
  title: {
    textAlign: "center",
    marginBottom: '2.2rem',
    fontWeight: 500
  }
};

class Page extends React.Component {
  render() {
    const { classes, title, children } = this.props;
    return (
      <>
        <Header />
        <Grid
          className={classes.container}
          container
          direction="row"
          justify="center"
        >
          <Grid
            className={classes.contentBox}
            item
          >
            {title ? (
              <Typography
                className={classes.title}
                variant="h2"
                gutterBottom
                color='primary'

              >
                {title}
              </Typography>
            ) : null}
            {children}
            <Footer />
          </Grid>
        </Grid>
        <CookieConsent
            buttonStyle={{ backgroundColor: "#44acc9", color: 'white' }}
            enableDeclineButton
            flipButtons
            declineButtonText='No thanks'
            declineButtonStyle={{backgroundColor: "transparent", border: '1px solid red'}}
        >
          We would like to use cookies to help us make this site more useful to you.   <Link to='/legal/cookies' style={{marginLeft: 40, color: '#44acc9'}}>Cookie Policy</Link>
        </CookieConsent>
      </>
    );
  }
}

export default withStyles(styles)(Page);
